export default {
  number: "מספר",
  print: "הדפסה",
  downloadOrigin: "להורדת המקור",
  downloadCopy: "להורדת העתק נאמן למקור",
  whatsAppSharing: "שיתוף ב- WhatsApp",
  emailSharing: 'שיתוף בדוא"ל',
  documentProduced: "הפקנו את המסמך שלך והוא כעת זמין לצפייה ושיתוף",
  refreshBrowser: "לא הצלחת למצוא אותו במסמכים או בתיקיית הלקוח? מומלץ לרענן את העמוד ולנסות שוב.",
  viewDocument: "צפייה במסמך",
  close: "סגירה"
}