export default {
  newAppointment: "תור חדש",
  updateAppointment: "עידכון תור",
  date: "תאריך",
  color: "צבע",
  startTime: "משעה",
  duration: "משך הפגישה",
  addToWaitingList: "הוספה לרשימת המתנה",
  doctor: "רופא מטפל",
  services: "שירותים",
  treatmentCode: "קוד טיפול",
  treatmentType: "סוג טיפול",
  treatments: "הוספה מתוכניות טיפול",
  notes: "הערות",
  newPatient: "מטופל חדש",
  existingPatient: "מטופל קיים",
  firstName: "שם פרטי",
  lastName: "שם משפחה",
  passportNumber: "תעודת זהות",
  city: "עיר מגורים",
  phone1: "טלפון",
  phone2: "טלפון נוסף",
  patient: "מטופל",
  cancel: "ביטול",
  save: "שמירה",
  dateUnavailable: "לרופא המשוייך אין יומן",
  anotherAppointment: {
    text: "קיים תור אחר באותה השעה, האם להמשיך?",
    yes: "קביעת התור",
    no: "ביטול"
  },
  popupDelete: {
    title: "מחיקת פגישה",
    text: "האם למחוק את הפגישה?",
    accept: "אישור",
    cancel: "ביטול",
    moveToWaitlist: "העבר לרשימת המתנה",
  },
  popupLink: {
    header: "שליחת קישור לזימון תור",
    linkMessage: "קישור לזימון תור יצורף אוטומטית בסוף ההודעה.",
    successMessage: "ההודעה נשלחה בהצלחה"
  }
}
