<template>
  <div class="formkit-phone">
    <div class="row g-3">
      <div class="col-auto">
        <select class="form-select" v-model="settings.phonePrefix" @change="changePhoneNumber()" required :disabled="disabled">
          <option v-for="phonePrefix in phonePrefixes" :value="phonePrefix">{{ phonePrefix }}</option>
        </select>
      </div>
      <div class="col">
        <InputMask
          ref="inputMask"
          class="form-control"
          v-model="settings.phoneNumber"
          mask="999-9999"
          :auto-clear="false"
          @keydown="onKeyDown"
          @blur="changePhoneNumber"
          :disabled="disabled" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import InputMask from "primevue/inputmask";
  import { ref, reactive, computed, onMounted, nextTick } from "vue";

  const props = defineProps<{
    context: any;
  }>();

  const context = props.context;
  const attrs = computed(() => context?.attrs);

  const inputMask = ref<InstanceType<typeof InputMask>>();
  const input = computed(() => inputMask.value?.$el as HTMLInputElement);
  const disabled = computed(() => attrs.value._disabled ?? context.disabled);

  onMounted(() => {
    context.redraw = redraw;
    nextTick(() => redraw());
    context.node.props.validation = validation.value;
  });

  // https://en.wikipedia.org/wiki/Telephone_numbers_in_Israel
  const phonePrefixes = ref(
    Array.from({ length: 10 }, (v, i) => `05${i}`)
      .concat(Array.from({ length: 10 }, (v, i) => `07${i}`))
      .concat(["02", "03", "04", "08", "09"])
  );

  function validationStringToArray(value: string) {
    return value.split("|").map((x) => {
      let index = x.indexOf(":");
      if (index < 0) return [x];
      return [x.substring(0, index)].concat(x.substring(index + 1).split(","));
    });
  }

  const validation = computed(() => {
    let array = [];
    if (typeof context.node.props.validation === "string") {
      array = validationStringToArray(context.node.props.validation);
    } else {
      array = context.node.props.validation || [];
    }
    // add phone validation
    array.push(["matches", /^\d{9,16}$/]);
    // console.log(array);
    return array;
  });

  const settings = reactive({
    phonePrefix: "053",
    phoneNumber: ""
  });

  function changePhoneNumber() {
    if (settings.phoneNumber) {
      context?.node.input(`${settings.phonePrefix}${settings.phoneNumber.replace(/-/g, "")}`);
    } else {
      context?.node.input(null);
    }
  }

  function redraw() {
    if (context._value && context._value.length >= 9) {
      const phone = context._value;
      settings.phonePrefix = phonePrefixes.value.find((prefix) => phone.startsWith(prefix)) ?? settings.phonePrefix;
      const phoneNumber = phone.substring(settings.phonePrefix.length);
      settings.phoneNumber = phoneNumber.slice(0, 3) + "-" + phoneNumber.slice(3);
    } else {
      settings.phonePrefix = "053";
      settings.phoneNumber = "";
    }
  }

  function onKeyDown() {
    // https://gitlab.uco.co.il/med-form/med-form/-/issues/1397
    if (!settings.phoneNumber) {
      input.value.setSelectionRange(0, 0);
      console.log("onInput", settings.phoneNumber);
    }
  }

  defineExpose({
    redraw
  });
</script>

<style lang="scss">
  .formkit-phone {
    direction: ltr;

    select {
      min-width: 70px;
      background-position: 100% 50% !important;
    }
  }
</style>
