export default {
  businessType: {
    NONE: "None",
    COMPANY: "Company",
    INDIVIDUAL_BUSINESS: "Individual business",
    SMALL_BUSINESS: "Small business"
  },
  serviceType: {
    NONE: "None",
    SERVICE: "Service",
    ITEM: "Item"
  },
  socialNetwork: {
    FACEBOOK: "Facebook",
    INSTAGRAM: "Instagram",
    LINKDIN: "Linkdin",
    YOUTUBE: "Youtube",
    TWITTER: "Twitter",
    TIK_TOK: "TikTok"
  },
  appointmentStatus: {
    NONE: "None",
    NEW: "New",
    CANCELED: "Canceled",
    REMINDER_SENT: "Reminder sent",
    APPROVED: "Approved",
    TOOK_PLACE: "Took place",
    DID_NOT_COME: "Did not come",
    deleted: "Deleted",
  },
  taskStatus: {
    NONE: "None",
    NEW: "New",
    CLOSED: "Closed",
  },
  incomeType: {
    NONE: "None",
    ESTIMATE: "Estimate",
    PROFORMA_INVOICE: "Proforma invoice",
    INVOICE: "Invoice",
    INVOICE_RECEIPT: "Invoice receipt",
    RECEIPT: "Receipt",
    INVOICE_REFOUND: "Invoice refound",
    RECEIPT_REFOUND: "Receipt refound"
  },
  incomeStatus: {
    NONE: "None",
    OPEN: "Open",
    CLOSED: "Closed",
    INVOICED: "Invoiced",
    MANUALLY_CLOSED: "Manually closed",
    DRAFT: "Draft",
    WAIT_PAYMENT: "Wait payment"
  },
  medicalFormStatus: {
    NONE: "None",
    NEW: "New",
    SENT: "Sent",
    VIEWED: "Viewed",
    FILLED_UP: "Filled up",
  },
  receiptPaymentType: {
    NONE: "None",
    CHEQUE: "Cheque",
    BANK_TRANSFER: "Bank transfer",
    CREDIT_CARD: "Credit card",
    CASH: "Cash",
    WITH_HOLDING_TAX: "With holding tax"
  },
  creditDealType: {
    NONE: "None",
    REGULAR: "Regular",
    PAYMENTS: "Payments",
    CREDIT: "Credit",
    CHARGE_DECLINED: "Charge declined",
    OTHER: "Other"
  },
  creditBrand: {
    NONE: "None",
    ISRACARD: "Isracard",
    VISA: "Visa",
    MASTER_CARD: "Master card",
    AMERICAN_EXPRESS: "American express",
    DINERS: "Diners"
  },
  treatmentStageStatus: {
    NONE: "None",
    ESTIMATE: "Estimate",
    ACTIVE: "Active",
    FINISHED: "Finished",
    CANCELED: "Canceled",
  },
  frequency: {
    NONE: "None",
    ONE_TIME: "One time",
    WEEKLY: "Weekly",
    BI_WEEKLY: "Every bi week",
    MONTHLY: "Monthly",
    BI_MONTHLY: "Every bi month"
  },
  dayOfWeek: {
    SUNDAY: "Sunday",
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday"
  },
  evenOrOdd: {
    NONE: "None",
    EVEN: "Even",
    ODD: "Odd"
  },
  historyAction: {
    NONE: "None",
    CREATE: "Create",
    UPDATE: "Update",
    DELETE: "Delete",
  },
  vatType: {
    NONE: "None",
    NORMAL: "Normal",
    NO_VAT: "No vat"
  },
  vatBeforeOrInclude: {
    NONE: "None",
    BEFORE_VAT: "Before",
    INCLUDE_VAT: "Included"
  },
  messageProvider: {
    NONE: "None",
    WHATSAPP: "Whatsapp",
    SMS: "Sms",
    EMAIL: "Email"
  },
  messageTemplateType: {
    NONE: "None",
    GENERAL: "General",
    PATIENT_APPOINTMENT_CREATED: "PatientAppointmentCreated",
    PATIENT_APPOINTMENT_REMINDER: "PatientAppointmentReminder",
    PATIENT_APPOINTMENT_CANCELED: "PatientAppointmentCanceled",
    ACCOUNTANT_INCOMES_REPORT: "AccountantIncomesReport",
    ACCOUNTANT_EXPENSES_REPORT: "AccountantExpensesReport",
    LABORATORY_LABORATORY_WORK_CREATED: "LaboratoryLaboratoryWorkCreated",
    USER_NEW_TASK_CREATED: "UserNewTaskCreated",
    PATIENT_MEDICAL_FORM_POPUP: "PatientMedicalFormPopup",
    PATIENT_APPOINTMENT_LINK_POPUP: "PatientAppointmentLinkPopup",

    PATIENT_FINANCIAL_DOCUMENT_CREATED: "PatientFinancialDocumentCreated",
    PATIENT_FINANCIAL_DOCUMENT_POPUP: "PatientFinancialDocumentPopup",
    PATIENT_FINANCIAL_DOCUMENT_WITH_PAYMENT_LINK_POPUP: "PatientFinancialDocumentWithPaymentLinkPopup",
    PATIENT_FINANCIAL_DOCUMENT_WITH_PAYMENT_LINK_CREATED: "PatientFinancialDocumentWithPaymentLinkCreated",

    PATIENT_APPOINTMENT_FOLLOW_UP: "PatientAppointmentFollowUp",
    PATIENT_MEDICAL_FORM_REMINDER: "PatientMedicalFormReminder",
    PATIENT_TREATMENT_PLAN_POPUP: "PatientTreatmentPlanPopup",
    PATIENT_SELF_REGISTRATION_POPUP: "PatientSelfRegistrationPopup",
    PATIENT_PRESCRIPTION_CREATED: "PatientPrescriptionCreated",
    PATIENT_APPOINTMENT_CHANGED: "PatientAppointmentChanged",
    PATIENT_RECALL_POPUP: "PatientRecallPopup",
    PATIENT_DOCUMENT_POPUP: "PatientDocumentPopup",
  },
  laboratoryWorkStatus: {
    NONE: "None",
    NEW: "New",
    SENT_TO_LABORATORY: "Sent to laboratory",
    BACK_FROM_LABORATORY: "Back from laboratory",
    CLOSED: "Closed"
  },
  treatmentPaidStatus: {
    NONE: "None",
    NO: "No",
    YES: "Yes",
    PARTIAL: "Partial",
    IN_PLAN: "In plan",
    MARKED_AS_PAID: "Marked as paid",
  }
}
