
export default {
  head: {
    title: "Dashboard",
    meta: [
      { name: "description", content: "Dashboard" }
    ]
  },
  "title": "Dashboard",
  noPhone: "Outdated phone, can't switch to chat",
  select: {
    all: "All doctors",
    custom: "1 doctor selected | {count} doctors selected"
  },
  "managing_widgets": "Managing widgets",
  "header": {
    "welcome": "Welcome to the Medform system",
    "handle_tasks": "Tasks to handle",
    "unread_messages": "Unread messages",
    "unconfirmed_guests": "Unconfirmed guests for today",
    "potential_customers": "Potential customers",
    "debt_customers": "Debt customers"
  },
  "chart": {
    "title": "Invited",
    "week": "Week",
    "month": "Month"
  },
  "total": {
    "title": "Total for today",
    "label": "You are all invited for today",
    "data": {
      "invited": "arrival confirmed",
      "waiting": "arrival not confirmed",
      "canceled": "canceled"
    }
  },
  "box1": {
    "title": "Welcome to today",
    "dropdown": "All doctors",
    "table": [
      "Time",
      "Customer",
      "Treatment",
      "Doctor",
      "Status"
    ]
  },
  "box2": {
    "title": "Unconfirmed guests",
    "dropdown": [
      "Today",
      "Tomorrow",
      "After 2 days",
      "After 3 days"
    ],
    "table": [
      "Time",
      "Customer",
      "Treatment",
      "Doctor",
      // "Status"
    ]
  },
  "box3": {
    "title": "Waiting list",
    "table": [
      "Customer",
      "Treatment",
      "Doctor",
      "Available date",
      "Actions"
    ]
  },
  "box4": {
    "title": "Tasks",
    "dropdown": "All statuses",
    "table": [
      "Date",
      "Task",
      "Associated with",
      "Status"
    ],
    "status": {
      "NEW": "New",
      "CLOSED": "Closed",
    }
  },
  "box5": {
    "title": "Potential customers",
    "dropdown": "All statuses",
    "table": [
      "Customer",
      "Phone",
      "Reason",
      "Status",
      "Actions"
    ]
  },
  "box6": {
    "title": "Debt customers",
    "table": [
      "Customer",
      "Amount to pay"
    ]
  }
}
