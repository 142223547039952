export default {
  head: {
    title: "קבוצת מחירונים",
    meta: [
      { name: "description", content: "קבוצת מחירונים" }
    ]
  },
  createTitle: "הוספת קבוצת מחירונים",
  updateTitle: "עדכון קבוצת מחירונים {0}",
  nameUsed: "שם קבוצת כבר בשימוש",
  title: "שם קבוצה",
  displayOrder: "סדר תצוגה",
  active: "פעיל",
  default: "קבע כברירת מחדל"
}