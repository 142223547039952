import type { DateTimeString } from "@/models/interfaces";

export interface PricelistGroupSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt?: DateTimeString;

  title: string;

  isDefault: boolean;
  active: boolean;

  displayOrder: number;
}

export const pricelistGroupSchemaAllFields = `
  id
  createdAt
  updatedAt
  deletedAt
  title
  isDefault
  active
  displayOrder
`;