export default {
  forPatient: "In honor of:",
  forPassport: "Passport",
  copy: "A faithful copy of the original",
  forCompanyNumber: "(H.P.):",
  forPhone: "Phone",
  quantity: "Quantity",
  detail: "Detail",
  price: "Price",
  total: "Total",
  rounding: "Rounding",
  discount: "Discount",
  vat: "Vat",
  totalPayment: "Total payment",
  paymentDetails: "Payment details",
  paymentMethod: "Methods of payment",
  date: "Date",
  sum: "Sum",
  signature: "Signature",
  bySoftware: "Produced by Medform Software"
}