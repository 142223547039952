<template>
  <div class="p-formkit">
    <ckeditor
      ref="editor"
      :editor-url="editorUrl"
      :id="context.id"
      :config="editorConfig"
      :modelValue="context._value ?? ''"
      @update:modelValue="handleInput($event)"
      :disabled="attrs._disabled ?? context.disabled ?? false"
      :class="styleClass" />
  </div>
</template>

<!-- https://github.com/sfxcode/formkit-primevue/blob/main/src/components/PrimeEditor.vue -->

<script setup lang="ts">
  import { ref, computed, onMounted } from "vue";
  import { useI18n } from "vue-i18n";
  // https://github.com/mayasabha/ckeditor4-vue3
  import { component as ckeditor } from "@mayasabha/ckeditor4-vue3";

  const { locale } = useI18n();

  onMounted(() => {
    context.insertText = insertText;
  });

  // https://ckeditor.com/docs/ckeditor4/latest/guide/dev_vue.html#editor-instance
  // https://stackoverflow.com/a/10224232/22548940
  function insertText(text: string) {
    editor.value.instance.insertText(text);
  }

  // https://ckeditor.com/docs/ckeditor4/latest/guide/dev_vue.html
  // https://ckeditor.com/docs/ckeditor4/latest/examples/fullpreset.html
  // https://ckeditor.com/cke4/builder
  const editor = ref<any>();
  // https://github.com/ckeditor/ckeditor4-vue/issues/26#issuecomment-609599213
  const editorUrl = ref("/app/libs/ckeditor4/4.22.1/full-all/ckeditor.js"); // https://cdn.ckeditor.com/4.22.1/full-all/ckeditor.js
  // https://ckeditor.com/docs/ckeditor4/latest/features/toolbarconcepts.html#toolbar-groups-configuration
  const editorConfig = ref<any>({
    versionCheck: false, // https://forum.xwiki.org/t/cke-editor-warning-4-22-1-version-not-secure/14020/3
    language: locale.value,
    extraPlugins: "base64image,autogrow", // https://github.com/nmmf/base64image
    removePlugins: "exportpdf", // https://stackoverflow.com/a/68361140/22548940
    toolbarGroups: [
      { name: "document", groups: ["mode", "document", "doctools"] },
      { name: "clipboard", groups: ["clipboard", "undo"] },
      { name: "editing", groups: ["find", "selection", "spellchecker", "editing"] },
      { name: "forms", groups: ["forms"] },
      "/",
      { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
      { name: "paragraph", groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"] },
      { name: "links", groups: ["links"] },
      { name: "insert", groups: ["insert"] },
      "/",
      { name: "styles", groups: ["styles"] },
      { name: "colors", groups: ["colors"] },
      { name: "tools", groups: ["tools"] },
      { name: "others", groups: ["others"] }
      // { name: "about", groups: ["about"] }
    ],
    toolbar: [
      { name: "document", items: ["Source", "-", /*"Save",*/ "NewPage", /*"ExportPdf",*/ "Preview", "Print", "-", "Templates"] },
      { name: "clipboard", items: ["Cut", "Copy", "Paste", "PasteText", "PasteFromWord", "-", "Undo", "Redo"] },
      { name: "editing", items: ["Find", "Replace", "-", "SelectAll", "-", "Scayt"] },
      { name: "forms", items: ["Form", "Checkbox", "Radio", "TextField", "Textarea", "Select", "Button", "ImageButton", "HiddenField"] },
      "/",
      { name: "basicstyles", items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript", "-", "CopyFormatting", "RemoveFormat"] },
      {
        name: "paragraph",
        items: [
          "NumberedList",
          "BulletedList",
          "-",
          "Outdent",
          "Indent",
          "-",
          "Blockquote",
          "CreateDiv",
          "-",
          "JustifyRight",
          "JustifyCenter",
          "JustifyLeft",
          "JustifyBlock",
          "-",
          "BidiRtl",
          "BidiLtr"
          // "Language"
        ]
      },
      { name: "links", items: ["Link", "Unlink", "Anchor"] },
      { name: "insert", items: [/*"Image",*/ "base64image", "Table", "HorizontalRule", "Smiley", "SpecialChar", "PageBreak", "Iframe"] },
      "/",
      { name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
      { name: "colors", items: ["TextColor", "BGColor"] },
      { name: "tools", items: ["Maximize", "ShowBlocks"] }
      // { name: "about", items: ["About"] }
    ]
  });

  const props = defineProps<{
    context: any;
  }>();

  const context = props.context;
  const attrs = computed(() => context?.attrs);

  function handleInput(e: any) {
    context?.node.input(e);
  }

  const styleClass = computed(() => (context?.state.validationVisible && !context?.state.valid ? `${attrs.value?.class} p-invalid` : attrs.value?.class));
</script>
