export interface PricelistPriceSchema {
  pricelistId: number;
  price: number;
  serviceId: number;
}

export const pricelistPriceSchemaAllFields = `
  pricelistId
  price
  serviceId
`;