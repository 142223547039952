import type { App, Plugin } from "vue";
import * as Sentry from "@sentry/vue";
import router from "@/router";
import { useServices } from "@/services";
import { useStores } from "@/stores";

export const SentryPlugin: Plugin = {
  install(app: App) {
    if (import.meta.env.VITE_SENTRY_ACTIVE != "true") {
      return;
    }
    // https://docs.sentry.io/platforms/javascript/guides/vue/#vue-3
    Sentry.init({
      app,
      dsn: "https://7b6aedf5c0f8f83c6fc37c24b3a3095e@sentry.uco.co.il/15",
      environment: "Production-App",
      release: useServices().config.appVersion,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(), // https://docs.sentry.io/platforms/javascript/session-replay/
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: [
        "localhost",
        /^https?:\/\/win12\.tempurl\.info/,
        /^https?:\/\/online\.medform\.co\.il/,
        /^\//
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
    // https://docs.sentry.io/platforms/javascript/guides/vue/enriching-events/tags/
    Sentry.setTag("applayer", "frontend");
    Sentry.setTag("apptype", "app");

    // set user data
    router.afterEach((to, from, failure) => {
      const user = useStores().user.me;
      const scope = Sentry.getCurrentScope();
      if (user) {
        scope.setUser({
          id: user.id,
          email: user.email,
          username: user.userName
        });
      } else {
        scope.setUser(null);
      }
    });
  }
};
