import type { DateTimeString, TimeSpanString } from "@/models/interfaces";
import type { DiscountType, TreatmentPaidStatus } from "@/models/enums";

export interface TreatmentSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt: DateTimeString | null;

  startTime: DateTimeString | null;
  duration: TimeSpanString;

  appointmentId: number | null;
  patientId: number;
  serviceId: number | null;
  /** Returns name by ServiceId, otherwise ServiceName field */
  serviceName: string;
  pricelistId: number;
  doctorId: number;
  planId: number | null;
  statusId: number;
  finished: boolean;

  teeths: string;
  areas: string;
  description: string | null;

  quantity: number;
  price: number;
  discountType: DiscountType;
  discountValue: number;
  total: number;

  paid: number;
  paidStatus: TreatmentPaidStatus,
  doctorTreatmentPaymentId: number | null;

  imported: boolean;

  serviceCode: string;
  doctorName: string;
  doctorImage: string;
  planTitle: string | null;
  statusTitle: string;
  due: number;
  inInvoiceLine: boolean;
}

export const treatmentSchemaAllFields = [
  "id",
  "createdAt",
  "updatedAt",
  "deletedAt",
  "startTime",
  "duration",
  "appointmentId",
  "patientId",
  "serviceId",
  "serviceName",
  "pricelistId",
  "doctorId",
  "planId",
  "statusId",
  "finished",
  "teeths",
  "areas",
  "description",
  "quantity",
  "price",
  "discountType",
  "discountValue",
  "total",
  "paid",
  "paidStatus",
  "doctorTreatmentPaymentId",
  "imported",
  "serviceCode",
  "doctorName",
  "doctorImage",
  "planTitle",
  "statusTitle",
  "due",
  "inInvoiceLine",
];
