import { FinancialUtil, Keys } from "@/utils";
import { DiscountType, VatType, VatBeforeOrInclude, IncomeType, IncomeStatus } from "@/models/enums";
import type { SaveIncomeInput } from "@/models/api/mutations/IncomeModels";

// #region remove comment later
// task #1000:
// Ask Alexey should we do additional protection in this service,
// for case: (ui)income.vatType === VatType.NoVat && some of (ui)invoiceLine.vatBeforeOrInclude === VatBeforeOrInclude.IncludeVat,
// because ui alredy disable it if condition true and auto-set BeforeVat
// #endregion

export default class IncomeService {
  //#region Calculation Methods
  public static calculateAmountInvoiceBeforeVat(input: SaveIncomeInput, vatPercent: number) {
    input.amountInvoiceBeforeVat = 0;
    for (const line of input.invoiceLines) {
      let amountInvoiceBeforeVat = 0;
      if (line.vatBeforeOrInclude === VatBeforeOrInclude.BeforeVat) {
        amountInvoiceBeforeVat = FinancialUtil.round(line.pricePerUnit * line.quantity);
        line.amount = amountInvoiceBeforeVat;
      } else if (line.vatBeforeOrInclude == VatBeforeOrInclude.IncludeVat) {
        amountInvoiceBeforeVat = FinancialUtil.round(line.pricePerUnit * 100 / (100 + vatPercent) * line.quantity);
        line.amount = amountInvoiceBeforeVat;
      }
      input.amountInvoiceBeforeVat += amountInvoiceBeforeVat;
    }
    // additional rounding for [js-number-type]
    input.amountInvoiceBeforeVat = FinancialUtil.round(input.amountInvoiceBeforeVat);
    return input.amountInvoiceBeforeVat;
  }

  public static calculateAmountDiscount(input: SaveIncomeInput) {
    let amountDiscount = 0;
    if (input.discountType === DiscountType.Amount) {
      amountDiscount = input.discountValue;
    } else if (input.discountType === DiscountType.Percent) {
      amountDiscount = FinancialUtil.round(input.discountValue * input.amountInvoiceBeforeVat / 100);
    }
    input.amountDiscount = amountDiscount;
    return input.amountDiscount;
  }

  public static calculateAmountVat(input: SaveIncomeInput, vatPercent: number) {
    const { amountVat, amountInvoice, fractional } = IncomeService.computeRoundingData(input, vatPercent);
    if (input.rounding && fractional != 0 && input.amountInvoiceBeforeVat - input.amountDiscount >= 1.5) {
      let roundingPercent = 0;

      if (fractional >= 0.5) {
        roundingPercent = (amountInvoice + (1 - fractional)) / amountInvoice;
      } else if (fractional < 0.5) {
        roundingPercent = (amountInvoice - fractional) / amountInvoice;
      }

      input.amountVat = FinancialUtil.round(amountVat * roundingPercent);
    } else {
      input.amountVat = IncomeService.computeVatWithoutRounding(input, vatPercent);
    }
    return input.amountVat;
  }

  public static calculateAmountInvoice(input: SaveIncomeInput, vatPercent: number) {
    let amountInvoiceValue = 0;
    const { amountInvoice, fractional } = IncomeService.computeRoundingData(input, vatPercent);
    if (input.rounding && fractional != 0 && input.amountInvoiceBeforeVat - input.amountDiscount >= 1.5) {

      if (fractional >= 0.5) {
        input.amountInvoice = amountInvoice + (1 - fractional);
      } else if (fractional < 0.5) {
        input.amountInvoice = amountInvoice - fractional;
      }

    } else {
      amountInvoiceValue = input.amountInvoiceBeforeVat - input.amountDiscount + input.amountVat;
      // additional rounding for [js-number-type]
      input.amountInvoice = FinancialUtil.round(amountInvoiceValue);
    }
    return input.amountInvoice;
  }

  public static calculateAmountRounding(input: {
    vatType: VatType,
    amountRounding: number,
    rounding: boolean,
    amountInvoiceBeforeVat: number,
    amountDiscount: number,
    amountInvoice: number,
    amountVat: number
  }, vatPercent: number) {
    input.amountRounding = 0;

    const { amountVat, amountInvoice, fractional } = IncomeService.computeRoundingData(input, vatPercent);
    if (input.rounding && fractional != 0 && input.amountInvoiceBeforeVat - input.amountDiscount >= 1.5) {
      let roundingPercent = 0;

      if (fractional >= 0.5) {
        roundingPercent = (amountInvoice + (1 - fractional)) / amountInvoice;
        input.amountInvoice = amountInvoice + (1 - fractional);
      } else if (fractional < 0.5) {
        roundingPercent = (amountInvoice - fractional) / amountInvoice;
        input.amountInvoice = amountInvoice - fractional;
      }

      input.amountVat = FinancialUtil.round(amountVat * roundingPercent);
      const amountRounding = input.amountInvoice - input.amountInvoiceBeforeVat + input.amountDiscount - input.amountVat;
      // additional rounding for [js-number-type]
      input.amountRounding = FinancialUtil.round(amountRounding);
    }
    return input.amountRounding;
  }

  public static computeVatWithoutRounding(input: { vatType: VatType, amountInvoiceBeforeVat: number, amountDiscount: number }, vatPercent: number) {
    let amountVat = 0;
    if (input.vatType === VatType.Normal) {
      amountVat = FinancialUtil.round((input.amountInvoiceBeforeVat - input.amountDiscount) * vatPercent / 100 + FinancialUtil.amountVatAdditionBeforeRounding);
    } else if (input.vatType == VatType.NoVat) {
      amountVat = 0;
    }
    return amountVat;
  }

  private static computeWithoutRounding(input: { vatType: VatType, amountInvoiceBeforeVat: number, amountDiscount: number }, vatPercent: number) {
    const amountVat = IncomeService.computeVatWithoutRounding(input, vatPercent);
    const amountInvoice = input.amountInvoiceBeforeVat - input.amountDiscount + amountVat;
    return {
      amountVat,
      amountInvoice
    };
  }

  private static computeRoundingData(input: { vatType: VatType, amountInvoiceBeforeVat: number, amountDiscount: number }, vatPercent: number) {
    const { amountVat, amountInvoice } = IncomeService.computeWithoutRounding(input, vatPercent);
    const fractional = amountInvoice - Math.floor(amountInvoice);
    return {
      fractional,
      amountVat,
      amountInvoice
    };
  }
  //#endregion

  //#region Map Methods
  public static mapIncomeType(type: IncomeType) {
    switch (type) {
      case IncomeType.Estimate: return 1;
      case IncomeType.ProformaInvoice: return 2;
      case IncomeType.Invoice: return 3;
      case IncomeType.InvoiceReceipt: return 4;
      case IncomeType.Receipt: return 5;
      case IncomeType.InvoiceRefound: return 6;
      case IncomeType.ReceiptRefound: return 7;
      default: throw new Error("something went wrong");
    }
  }

  public static mapIncomeStatus(status: IncomeStatus) {
    switch (status) {
      case IncomeStatus.Open: return 1;
      case IncomeStatus.Closed: return 2;
      case IncomeStatus.Invoiced: return 3;
      case IncomeStatus.ManuallyClosed: return 4;
      case IncomeStatus.Draft: return 5;
      case IncomeStatus.WaitPayment: return 6;
      default: throw new Error("something went wrong");
    }
  }

  public static getDocumentContentKey(type: IncomeType | null | undefined) {
    switch (type) {
      case IncomeType.Estimate: return Keys.Document.EstimateText;
      case IncomeType.ProformaInvoice: return Keys.Document.ProformaInvoiceText;
      case IncomeType.Invoice: return Keys.Document.InvoiceText;
      case IncomeType.InvoiceReceipt: return Keys.Document.InvoiceReceiptText;
      case IncomeType.Receipt: return Keys.Document.ReceiptText;
      case IncomeType.InvoiceRefound: return Keys.Document.InvoiceRefoundText;
      case IncomeType.ReceiptRefound: return Keys.Document.ReceiptRefoundText;
      default: return null;
    }
  }
  //#endregion
}
