import type { VatBeforeOrInclude } from "@/models/enums";

export interface InvoiceLineSchema {
  id: number;
  createdAt: string;
  incomeId: number;
  treatmentId: number | null;
  treatmentPlanId: number | null;
  serviceId: number | null;
  description: string | null;
  serviceName: string | null;
  quantity: number;
  pricePerUnit: number;
  amount: number;
  vatBeforeOrInclude: VatBeforeOrInclude;
}

export const invoiceLineSchemaAllFields = [
  "id",
  "createdAt",
  "incomeId",
  "treatmentId",
  "treatmentPlanId",
  "serviceId",
  "description",
  "serviceName",
  "quantity",
  "pricePerUnit",
  "amount",
  "vatBeforeOrInclude"
];
