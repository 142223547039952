
export default {
  head: {
    title: "דשבורד",
    meta: [
      { name: "description", content: "דשבורד" }
    ]
  },
  "title": "דשבורד",
  noPhone: "טלפון לא מעודכן, לא ניתן לעבור לצ'ט",
  select: {
    all: "כל הרופאים",
    custom: "רופא אחד נבחר | נבחרו {count} רופאים"
  },
  "managing_widgets": "ניהול ווידג׳טים",
  "header": {
    "welcome": "ברוכה הבאה למערכת Medform",
    "handle_tasks": "משימות לטיפול",
    "unread_messages": "הודעות שטרם נקראו",
    "unconfirmed_guests": "מוזמנים להיום שטרם אישרו הגעה",
    "potential_customers": "לקוחות פוטנציאליים",
    "debt_customers": "לקוחות חייבים"
  },
  "chart": {
    "title": "מוזמנים",
    "week": "שבוע",
    "month": "חודש"
  },
  "total": {
    "title": "סה״כ להיום",
    "label": "סה״כ מוזמנים להיום",
    "data": {
      "invited": "אישרו הגעה",
      "waiting": "טרם אישרו הגעה",
      "canceled": "ביטלו"
    }
  },
  "box1": {
    "title": "מוזמנים להיום",
    "dropdown": "כל הרופאים",
    "table": [
      "שעה",
      "שם מטופל",
      "טיפול",
      "רופא מטפל",
      "סטטוס"
    ]
  },
  "box2": {
    "title": "מוזמנים שטרם אישרו הגעה",
    "dropdown": [
      "היום",
      "מחר",
      "עוד יומיים",
      "עוד שלושה ימים"
    ],
    "table": [
      "שעה",
      "שם מטופל",
      "טיפול",
      "רופא מטפל",
      // "אישור"
    ]
  },
  "box3": {
    "title": "רשימת המתנה",
    "table": [
      "שם מטופל",
      "טיפול",
      "רופא מטפל",
      "תאריך פנוי",
      "שליחת הודעה"
    ]
  },
  "box4": {
    "title": "משימות",
    "dropdown": "כל הסטטוסים",
    "table": [
      "תאריך",
      "משימה",
      "משוייך ל",
      "סטטוס"
    ],
    "status": {
      "NEW": "חדש",
      "CLOSED": "סגור",
    }
  },
  "box5": {
    "title": "לקוחות פוטנציאלים",
    "dropdown": "כל הסטטוסים",
    "table": [
      "שם מטופל",
      "טלפון",
      "סיבת הפניה",
      "סטטוס",
      "שליחת הודעה"
    ]
  },
  "box6": {
    "title": "לקוחות חייבים",
    "table": [
      "שם מטופל",
      "סכום לתשלום"
    ]
  }
}
