export default {
  head: {
    title: "איפוס סיסמה",
    meta: [
      { name: "description", content: "איפוס סיסמה" }
    ]
  },
  title: "איפוס סיסמה",
  alert: "הכניסו שם משתמש ונשלח אליכם דוא״ל לאיפוס הסיסמה",
  input: {
    username: "שם משתמש"
  },
  reset: "שליחה",
  instructionsSent: "נשלח קישור לאיפוס הסיסמה אם קיים חשבון עם שם המשתמש שסופק. אנא בדוק את הדואר האלקטרוני שלך.",
  loginText: "להתחברות",
  loginLink: "לחצו כאן"
}
