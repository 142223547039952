import GraphqlService from "../GraphqlService";
import type { SaveSupportMessageInput } from "@/models/api/mutations/SupportMessageModels";

export default class SupportMessageService {
  async saveSupportMessage(input: SaveSupportMessageInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "saveSupportMessage", variables: [
        { field: "email", value: input.email, valueType: "String!" },
        { field: "url", value: input.url, valueType: "String!" },
        { field: "content", value: input.content, valueType: "String!" },
      ]
    });
    return data ?? false;
  }
}
