import ConfigService from "@/services/ConfigService";
import IncomeService from "@/services/IncomeService";
import moment from "@/plugins/VueMomentPlugin";
import { useStores } from "@/stores";
import { delay, HtmlHelper } from "@/utils";
import { i18n } from "@/locales/i18n";
import type { FilterIncomeReportsInput } from "@/models/api/queries/IncomeModels";
import type { AppointmentCalendarDaysInput } from "@/models/api/queries/AppointmentReminderModels";
import type { AdvanceTaxReportInput, VatReportInput, ProfitAndLossReportInput } from "@/models/api/queries/FinancialReportModels";

export default class PdfService {
  private async submitForm(form: HTMLFormElement, download: boolean, print: boolean) {
    if (download) {
      form.target = '_blank';
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    } else {
      form.style.display = 'none';
      // https://stackoverflow.com/a/38262246/22548940
      const printWindow = window.open() as Window;
      printWindow.document.body.appendChild(form);
      form.submit();
      if (print) {
        async function printWindowDocument() {
          await delay(333);
          if (printWindow.document.readyState === 'complete') {
            // https://stackoverflow.com/questions/61637833/not-detecting-afterprint-from-pdf
            for (let i = 0; i < 1; i++) {
              await delay(1000);
              printWindow.print();
            }
          } else {
            await printWindowDocument();
          }
        }
        await printWindowDocument();
      }
    }
  }

  public printPatientPrescriptions(patientId: number, prescriptionIds: number[], printPrescriptions: boolean, printInstructions: boolean, download: boolean) {
    const dbName = useStores().database.dbName;
    const form = HtmlHelper.createElement<HTMLFormElement>(`
    <form action="${ConfigService.api(`pdf/patient/${patientId}/prescriptions`)}" method="post">
      <input type="text" name="DBNAME" value="${dbName}" />
      ${prescriptionIds.map(id => `<input type="number" name="prescriptionIds[]" value="${id}" />`).join('')}
      <input type="text" name="printPrescriptions" value="${printPrescriptions ?? false}" />
      <input type="text" name="printInstructions" value="${printInstructions ?? false}" />
      <input type="text" name="download" value="${download ?? false}" />
      <button type="submit">print</button>
    </form>`);

    this.submitForm(form, download, true);
  }

  public printPatientDocument(patientId: number, documentId: number, download: boolean) {
    const dbName = useStores().database.dbName;
    const form = HtmlHelper.createElement<HTMLFormElement>(`
    <form action="${ConfigService.api(`pdf/patient/${patientId}/documents/${documentId}`)}" method="post">
      <input type="text" name="DBNAME" value="${dbName}" />
      <input type="text" name="download" value="${download ?? false}" />
      <button type="submit">print</button>
    </form>`);

    this.submitForm(form, download, true);
  }

  public printDocumentTemplate(templateId: number, download: boolean) {
    const dbName = useStores().database.dbName;
    const form = HtmlHelper.createElement<HTMLFormElement>(`
    <form action="${ConfigService.api(`pdf/templates/documents/${templateId}`)}" method="post">
      <input type="text" name="DBNAME" value="${dbName}" />
      <input type="text" name="download" value="${download ?? false}" />
      <button type="submit">print</button>
    </form>`);

    this.submitForm(form, download, false);
  }

  public printPatientMedicalForm(patientId: number, formId: number, download: boolean, print?: boolean) {
    const dbName = useStores().database.dbName;
    const form = HtmlHelper.createElement<HTMLFormElement>(`
    <form action="${ConfigService.api(`pdf/patient/${patientId}/forms/${formId}?download=${download}`)}" method="post">
      <input type="text" name="DBNAME" value="${dbName}" />
      <button type="submit">print</button>
    </form>`);

    this.submitForm(form, download, print ?? true);
  }

  public printDepositCash(depositCashId: number, download?: boolean) {
    const dbName = useStores().database.dbName;
    const locale = i18n.global.locale.value;
    const form = HtmlHelper.createElement<HTMLFormElement>(`
    <form action="${ConfigService.api(`pdf/deposit/cash/${depositCashId}`)}" method="post">
      <input type="text" name="DBNAME" value="${dbName}" />
      <input type="text" name="locale" value="${locale}" />
      <input type="text" name="download" value="${download ?? false}" />
      <button type="submit">print</button>
    </form>`);

    this.submitForm(form, download ?? false, true);
  }

  public printTreatmentPlan(patientId: number, treatmentPlanId: number, download: boolean, print?: boolean) {
    const dbName = useStores().database.dbName;
    const locale = i18n.global.locale.value;
    const form = HtmlHelper.createElement<HTMLFormElement>(`
    <form action="${ConfigService.api(`pdf/patient/${patientId}/plans/${treatmentPlanId}`)}" method="post">
      <input type="text" name="DBNAME" value="${dbName}" />
      <input type="text" name="locale" value="${locale}" />
      <input type="text" name="download" value="${download ?? false}" />
      <button type="submit">print</button>
    </form>`);

    this.submitForm(form, download, print ?? true);
  }

  public printIncome(incomeId: number, copy: boolean, download?: boolean, print?: boolean) {
    const dbName = useStores().database.dbName;
    const form = HtmlHelper.createElement<HTMLFormElement>(`
    <form action="${ConfigService.api(`pdf/income/${incomeId}`)}" method="post">
      <input type="text" name="DBNAME" value="${dbName}" />
      <input type="text" name="copy" value="${copy}" />
      <input type="text" name="download" value="${download ?? false}" />
      <button type="submit">print</button>
    </form>`);

    this.submitForm(form, download ?? false, print ?? false);
  }

  public printIncomeReports(input: FilterIncomeReportsInput, locale: string) {
    const types = input.incomeTypes!.map(inc => IncomeService.mapIncomeType(inc));

    const dbName = useStores().database.dbName;
    const form = HtmlHelper.createElement<HTMLFormElement>(`
    <form action="${ConfigService.api(`pdf/income-reports?locale=${locale}`)}" method="post">
      <input type="text" name="DBNAME" value="${dbName}" />
      <input type="number" name="patientId" value="${input.patientId ?? ""}" />
      <input type="date" name="dateFrom" value="${input.dateFrom ? moment(input.dateFrom).format('YYYY-MM-DD') : ""}" />
      <input type="date" name="dateTo" value="${input.dateTo ? moment(input.dateTo).format('YYYY-MM-DD') : ""}" />
      ${input.emails?.map(email => `<input type="text" name="emails[]" value="${email}" />`)?.join('') ?? ""}
      ${types.map(type => `<input type="number" name="incomeTypes[]" value="${type}" />`).join('')}
      <button type="submit">print</button>
    </form>`);

    this.submitForm(form, true, false);
  }

  public printVatReports(input: VatReportInput, locale: string) {
    const dbName = useStores().database.dbName;
    const form = HtmlHelper.createElement<HTMLFormElement>(`
    <form action="${ConfigService.api(`pdf/vat-reports?locale=${locale}`)}" method="post">
      <input type="text" name="DBNAME" value="${dbName}" />
      <input type="date" name="dateFrom" value="${input.dateFrom ? moment(input.dateFrom).format('YYYY-MM-DD') : ""}" />
      <input type="date" name="dateTo" value="${input.dateTo ? moment(input.dateTo).format('YYYY-MM-DD') : ""}" />
    </form>`);

    this.submitForm(form, true, false);
  }

  public printAdvanceTaxReports(input: AdvanceTaxReportInput, locale: string) {
    const dbName = useStores().database.dbName;
    const form = HtmlHelper.createElement<HTMLFormElement>(`
    <form action="${ConfigService.api(`pdf/advance-tax-reports?locale=${locale}`)}" method="post">
      <input type="text" name="DBNAME" value="${dbName}" />
      <input type="date" name="dateFrom" value="${input.dateFrom ? moment(input.dateFrom).format('YYYY-MM-DD') : ""}" />
      <input type="date" name="dateTo" value="${input.dateTo ? moment(input.dateTo).format('YYYY-MM-DD') : ""}" />
    </form>`);

    this.submitForm(form, true, false);
  }

  public printProfitAndLossReports(input: ProfitAndLossReportInput, locale: string) {
    const dbName = useStores().database.dbName;
    const form = HtmlHelper.createElement<HTMLFormElement>(`
    <form action="${ConfigService.api(`pdf/profit-and-loss-reports?locale=${locale}`)}" method="post">
      <input type="text" name="DBNAME" value="${dbName}" />
      <input type="date" name="dateFrom" value="${input.dateFrom ? moment(input.dateFrom).format('YYYY-MM-DD') : ""}" />
      <input type="date" name="dateTo" value="${input.dateTo ? moment(input.dateTo).format('YYYY-MM-DD') : ""}" />
      ${input.emails?.map(email => `<input type="text" name="emails[]" value="${email}" />`)?.join('') ?? ""}
    </form>`);

    this.submitForm(form, true, false);
  }

  public printCalendarDays(input: AppointmentCalendarDaysInput) {
    const dbName = useStores().database.dbName;
    const locale = i18n.global.locale.value;
    const format = "YYYY-MM-DDTHH:mm:ss";
    const form = HtmlHelper.createElement<HTMLFormElement>(`
    <form action="${ConfigService.api(`pdf/calendar/days?locale=${locale}`)}" method="post">
      <input type="text" name="DBNAME" value="${dbName}" />
      <input type="datetime-local" name="dateFrom" value="${input.dateFrom ? moment(input.dateFrom).startOf("day").format(format) : ""}" />
      <input type="datetime-local" name="dateTo" value="${input.dateTo ? moment(input.dateTo).endOf("day").format(format) : ""}" />
      <input type="number" name="doctorId" value="${input.doctorId.toString()}" />
      <input type="text" name="download" value="${input.download}" />
    </form>`);

    this.submitForm(form, false, true);
  }
}
