import GraphqlService from "../GraphqlService";
import { pricelistGroupSchemaAllFields, pricelistPriceSchemaAllFields, pricelistSchemaAllFields as fields } from "@/models/schemas";
import type { PricelistGroupSchema, PricelistPriceSchema, PricelistSchema } from "@/models/schemas";
import type { TabulatorParams } from "@/models/interfaces";
import type { SavePricelistGroupInput, SavePricelistPriceInput, UpdatePricelistPricesInput } from "@/models/api/mutations/PricelistModels";

export default class PricelistService {
  async getPricelists(params: TabulatorParams) {
    return await GraphqlService.getItems<PricelistSchema>("pricelists", fields, params);
  }

  async getPricelistByService(id: number) {
    return await GraphqlService.getItem<PricelistSchema>("pricelistByService", fields, id);
  }

  async getPricelistServicePrice(id: number, serviceId: number, defaultOnZero?: boolean) {
    return await GraphqlService.getItem<PricelistPriceSchema>("pricelistServicePrice", pricelistPriceSchemaAllFields, id, {
      variables: [
        { field: "serviceId", value: serviceId, valueType: "ID!" },
        { field: "defaultOnZero", value: defaultOnZero ?? false, valueType: "Boolean!" },
      ]
    });
  }

  async getPricelistGroupsAll(selectedId?: number) {
    const { data } = await GraphqlService.queryGql<PricelistGroupSchema[]>({
      method: "pricelistGroupsAll",
      fields: pricelistGroupSchemaAllFields,
      variables: [{ field: "selectedId", value: selectedId, valueType: "ID" }]
    })
    return data ?? [];
  }

  async savePricelistServicePrice(input: SavePricelistPriceInput) {
    return await GraphqlService.setItem<PricelistPriceSchema>("savePricelistPrice", "pricelistServicePrice", pricelistPriceSchemaAllFields, input);
  }

  async getPricelistGroups(params: TabulatorParams) {
    return await GraphqlService.getItems<PricelistGroupSchema>("pricelistGroups", pricelistGroupSchemaAllFields, params);
  }

  async deletePricelistGroup(id: number) {
    return await GraphqlService.deleteItem("deletePricelistGroup", id);
  }

  async getPricelistGroup(id: number) {
    return await GraphqlService.getItem<PricelistGroupSchema>("pricelistGroup", pricelistGroupSchemaAllFields, id);
  }

  async savePricelistGroup(input: SavePricelistGroupInput) {
    return await GraphqlService.setItem<PricelistGroupSchema>("savePricelistGroup", "pricelist", pricelistGroupSchemaAllFields, input);
  }

  async updatePricelistPrices(input: UpdatePricelistPricesInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "updatePricelistPrices", variables: [
        { field: "pricelistId", value: input.pricelistId, valueType: "Int!" },
        { field: "percentage", value: input.percentage, valueType: "Float!" },
        { field: "priceOperationType", value: input.priceOperationType, valueType: "PriceOperationType!" },
      ]
    });
    return data;
  }
}
