import type { PricelistPriceSchema } from "@/models/schemas";

export interface PricelistSchema {
  serviceName: string;

  serviceId: number;

  prices: PricelistPriceSchema[];

  categoryName: string;
}

export const pricelistSchemaAllFields = `
  serviceName
  serviceId
  prices
  categoryName
`;
