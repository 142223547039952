import GraphqlService from "../GraphqlService";
import { type MessageProvider, MessageTemplateType } from "@/models/enums";
import { type MessageTemplateSchema, messageTemplateSchemaAllFields as fields } from "@/models/schemas";
import type { TabulatorParams, Filter, Result } from "@/models/interfaces";
import type { SaveMessageTemplateInput, SendTestMessageTemplateInput } from "@/models/api/mutations/MessageTemplateModels";
import type { MessageTemplateOptions } from "@/models/api/queries/MessageTemplateModels";

export default class MessageTemplateService {
  //#region Query
  async getMessageTemplates(params: TabulatorParams) {
    return await GraphqlService.getItems<MessageTemplateSchema>("messageTemplates", fields, params);
  }

  async getMessageTemplatesAll(filter?: Filter[]) {
    const { data } = await GraphqlService.queryGql<MessageTemplateSchema[]>({ method: "messageTemplatesAll", fields: fields, filter: filter })
    return data ?? [];
  }

  async getMessageTemplateAttachmentIds(id: number) {
    const { data } = await GraphqlService.queryGql<Result<number[]>>({ method: "messageTemplateAttachmentIds", variables: [{ field: "id", value: id, valueType: "ID!" }] })
    return data?.data ?? [];
  }

  async getMessageTemplate(id: number) {
    return await GraphqlService.getItem<MessageTemplateSchema>("messageTemplate", fields, id);
  }

  async getActiveMessageTemplateAsync(type: MessageTemplateType, provider: MessageProvider, options: MessageTemplateOptions) {
    const { data } = await GraphqlService.queryGql<MessageTemplateSchema>({
      method: "activeMessageTemplate",
      variables: [
        { field: "type", value: type, valueType: "MessageTemplateType!" },
        { field: "provider", value: provider, valueType: "MessageProvider!" },
        { field: "options", value: options, valueType: "MessageTemplateOptionsInput" },
      ],
      fields: fields,
    });
    return data;
  }

  async getMessageTemplateForReminder(id: number, appointmentId: number) {
    const { data } = await GraphqlService.queryGql<MessageTemplateSchema>({
      method: "messageTemplateForReminderMessage",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "appointmentId", value: appointmentId, valueType: "Int!" },
      ],
      fields: fields,
    });
    return data;
  }

  async getMessageTemplateForRecall(id: number, appointmentId: number, newAppointmentId?: number) {
    const { data } = await GraphqlService.queryGql<MessageTemplateSchema>({
      method: "messageTemplateForRecallMessage",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "appointmentId", value: appointmentId, valueType: "Int!" },
        { field: "newAppointmentId", value: newAppointmentId, valueType: "Int" },
      ],
      fields: fields,
    });
    return data;
  }

  async getMessagesTemplateByPlatform(platform: MessageProvider) {
    const filter: Filter[] = [
      { field: "active", type: "=", value: true },
      { field: "platform", type: "=", value: platform },
      { field: "type", type: "=", value: MessageTemplateType.General }
    ];
    return await this.getMessageTemplatesAll(filter);
  }
  //#endregion

  //#region Mutation
  async sendTestMessageTemplate(input: SendTestMessageTemplateInput) {
    const { data } = await GraphqlService.mutateGql<Result>({
      method: "sendTestMessageTemplate",
      fields: "success message",
      variables: [{ field: "input", value: input, valueType: "SendTestMessageTemplateInput!" }]
    });
    return data;
  }

  async setActiveValueForMessageTemplate(id: number, active: boolean) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "setActiveValueForMessageTemplate",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "active", value: active, valueType: "Boolean!" }
      ]
    });
    return data ?? false;
  }

  async deleteMessageTemplate(id: number) {
    return await GraphqlService.deleteItem("deleteMessageTemplate", id);
  }

  async saveMessageTemplate(input: SaveMessageTemplateInput) {
    return await GraphqlService.setItem<MessageTemplateSchema>("saveMessageTemplate", "messageTemplate", fields, input);
  }
  //#endregion
}
