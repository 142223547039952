<template>
  <div class="p-formkit">
    <ckeditor
      ref="wrapper"
      tag-name="textarea"
      :editor-url="editorUrl"
      :config="config"
      :class="styleClass"
      :disabled="attrs._disabled ?? context.disabled ?? false"
      :modelValue="context._value ?? ''"
      @update:modelValue="handleInput($event)" />
  </div>
</template>

<script setup lang="ts">
  import { component as ckeditor } from "@mayasabha/ckeditor4-vue3";
  import { ref, computed, onMounted } from "vue";
  import { useI18n } from "vue-i18n";
  import { Popup } from "@/utils";

  const { locale } = useI18n();
  const props = defineProps<{ context: any }>();

  const context = props.context;
  const config = {
    versionCheck: false, // https://forum.xwiki.org/t/cke-editor-warning-4-22-1-version-not-secure/14020/3
    language: locale.value,
    removePlugins: "exportpdf,elementspath", // https://stackoverflow.com/a/68361140/22548940
    resize_enabled: false, // https://ckeditor.com/old/forums/CKEditor-3.x/remove-footer
    toolbar: [{ name: "clipboard", items: ["Undo", "Redo"] }, "-", { name: "basicstyles", items: ["Bold", "Italic"] }]
  };

  const wrapper = ref();
  const editorUrl = ref("/app/libs/ckeditor4/4.22.1/full-all/ckeditor.js");

  const editor = computed(() => wrapper.value.instance);
  const attrs = computed(() => context?.attrs);
  const styleClass = computed(() => (context?.state.validationVisible && !context?.state.valid ? `${attrs.value?.class} p-invalid` : attrs.value?.class)); // todo: make work later

  function handleInput(e: any) {
    context?.node.input(e);
  }

  function insertText(text: string) {
    try {
      const model = editor.value?.model;
      if (model) {
        model.change((writer: any) => {
          // todo: always append to the end
          const position = model.document.selection.getFirstPosition();
          if (position) writer.insertText(text, position);
        });
      }
    } catch (error) {
      Popup.error(error);
      console.log(error);
    }
  }

  onMounted(() => {
    try {
      if (wrapper.value) {
        context.insertText = insertText;
      } else {
        console.log("[CkMessageEditor] current value:");
        console.log(wrapper.value);
      }
    } catch (error) {
      Popup.error(error);
      console.log(error);
    }
  });
</script>
