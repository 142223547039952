export default {
  head: {
    title: "Patient",
    meta: [{ name: "description", content: "Patient description" }]
  },
  backToList: "Back to patients",
  edit: "Edit",
  patientNumber: "Patient number",
  patientBalance: "Patient balance",
  doctor_warn: "The doctor doesn't have a calendar",
  selectSection: "Select section",
  view: {
    debt: "Debt",
    balance: "Balance",
    phone1: "Phone",
    phone2: "Phone",
    email1: "Email",
    doctor: "Doctor",
    city: "City",
    id: "Patient number",
    passportNumber: "Passport",
    fileNumber: "Case number",
    priceList: "Price list",
    attendingDoctor: "Attending doctor",
    photos: {
      title: "Photos",
      options: ["Before", "After", "General"]
    },
    xRay: {
      title: "X-Ray",
      options: ["Before", "After", "General"]
    },
    cross: {
      title: "Cross",
      options: ["Before", "After", "General"]
    },
    appointments: {
      titleNext: "Next 3 appointments",
      titleLast: "Last 3 appointments",
      table: {
        columns: {
          id: "ID",
          startTime: "Date",
          serviceName: "Service",
          note: "Note"
        }
      },
      approve: "Approve appointment",
      cancel: "Cancel appointment",
    },
    tasks: {
      title: "Upcoming tasks",
      table: {
        columns: {
          id: "ID",
          followUp: "Date",
          title: "Note"
        },
      },
      close: "Close task"
    },
    forms: {
      title: "Forms",
      send: "Send form",
      message: "Medical form not filled up in last 12 month"
    },
    files: {
      title: "Upload files",
      drug: "Drug files here"
    }
  },
  form: {
    title: "Send a new patient registration form",
    linkSent: "Link to registration sent",
    linkToRegister: "Link to register",
    phoneRequired: "Phone number is required."
  },
  contact: {
    title: "Contact patient",
    template: "Template"
  },
  general: {
    createdAt: "Created at",
    doctor: "Doctor",
    priceList: "Price list",
    readonly: ["Data is displayed for viewing. To edit", "click here"],
    title: "General",
    personalInformation: "Personal Information",
    firstName: "Firstname",
    lastName: "Lastname",
    surName: "Surname",
    genderSelect: "Not selected",
    gender: "Gender",
    genderOptions: ["Male", "Female"],
    passportNumber: "Passport Number",
    familyStatus: "Family Status",
    birthDate: "Date of birth",
    familyOptions: ["Not selected", "Bachelor", "Married"],
    active: "Patient active status",
    activeOptions: ["Active", "Archived"],
    address: "Address",
    street: "Street",
    houseNumber: "House Number",
    apartment: "Apartment",
    city: "City",
    postalCode: "Postal Code",
    job: "Job",
    contacts: "Contacts",
    phone1: "Phone 1",
    phone2: "Phone 2",
    phone3: "Phone 3",
    email1: "Email 1",
    email2: "Email 2",
    fax: "Fax",
    insurrance: "Insurrance details",
    insurranceCompany: "Insurrance Company",
    policyNumber: "Policy Number ",
    endDate: "End date",
    conditions: "Conditions",
    employerNumber: "Employer Number",
    companyCode: "Insurrance company code",
    remarks: "Remarks",
    general: "General",
    color: "Color",
    fileNumber: "Case Number",
    referredBy: "Referred By",
    healthFund: "HMO",
    create: "Create",
    balance: {
      title: "Balance",
      alert: "If the client has a debt balance or a previous right, it can be updated here for tracking. The balance that will be set will affect the amount of the client's receipts, but not the income statement.",
      initial: "Balance update"
    },
    validation: {
      exists: "Patient exist in database.",
      viewBtn: "View patient card",
      continueBtn: "Continue saving",
    }
  },
  appointments: {
    title: "Appointments",
    selectColumns: "Select columns",
    newAppointment: "New appointment",
    columns: {
      startTime: "Start time",
      duration: "Duration",
      doctorName: "Doctor",
      serviceName: "Service",
      note: "Note",
      status: "Status"
    }
  },
  treatmentPlans: {
    title: "Treatment plans"
  },
  prescriptions: {
    title: "Prescriptions"
  },
  documents: {
    title: "Documents"
  },
  medicalForms: {
    title: "Medical forms"
  },
  notes: {
    title: "Notes"
  },
  treatments: {
    title: "Treatments"
  },
  files: {
    title: "Files"
  },
  tasks: {
    title: "Tasks"
  },
  laboratoryWorks: {
    title: "Laboratory works"
  },
  family: {
    title: "Family"
  },
  payments: {
    title: "Payments"
  },
  tip: {
    customerCard: "Customer card",
    treatmentPlan: "Treatment plan",
    careCard: "Care card",
    calendar: "Calendar",
    addForm: "Add form",
    recall: "Recall",
    newPrescription: "New prescription",
    invoice: "Invoice",
    addPayment: "Add payment",
    laboratoryWork: "Laboratory work",
    sendMessage: "Send message",
    sendEmail: "Send email",
    sendAppointment: "Send appointment link"
  }
};
