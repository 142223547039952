export default {
  title: "תשלומים",
  titleFamily: "חשבונות ש{0} משלם עבורם",
  buttons: {
    add: "הפקת מסמכים",
    addCopy: "שכפול",
    addRelated: "הפקת מסמך חדש מתוך מסמך זה",
    addCardPayment: "הוספת תשלום באשראי",
    send: "שליחת מסמך",
    view: "צפיה",
    reopen: "פתיחה מחודשת של המסמך",
    download: "הורדת מסמך"
  },
  form: {
    title: "הוספת מסמך הכנסות",
    documentType: "סוג מסמך",
    connect: "בחרו טיפולים",
    submit: "הפקת מסמך",
    typeError: "סוג הכנסה לא נתמך",
    receiptError: "סוג הקבלה אינו תומך בשורות החשבונית",
    total: "סכום",
    treatmentPlans: {
      title: "תוכניות טיפול",
      table: {
        columns: {
          createdAt: "תאריך יצירה",
          title: "כותרת",
          description: "תיאור",
          status: "סטטוס",
          total: "סכום",
          due: "יתרה"
        }
      }
    },
    treatments: {
      title: "טיפולים",
      table: {
        columns: {
          createdAt: "תאריך יצירה",
          description: "טיפול",
          statusTitle: "סטטוס",
          due: "יתרה"
        }
      }
    }
  },
  formCard: {
    treatmentPlansTitle: "תשלום מראש עבור תוכניות טיפול",
    treatmentsTitle: "תשלום עבור טיפולים שהסתיימו",
    title: "הוספת תשלום באשראי",
    subTotal: 'סה"כ',
    vat: 'מע"מ',
    rounding: "עיגול סכום",
    total: 'סה"כ לתשלום',
    submit: "המשך לתשלום",
    payment: {
      amount: "סכום לתשלום",
      paymentMaxNumber: "מספר תשלומים",
      vatType: "סוג המע״מ",
      vat: 'מע"מ',
      reason: "סיבת תשלום",
      method: "תשלום דרך",
      terminal: "מכשיר סליקה",
      form: "סליקה ידנית",
      link: {
        btn: "שליחת קישור",
        select: "איך לשלוח לינק לתשלום",
        options: {
          email: 'דוא"ל',
          whatsapp: "Whatsapp",
          sms: "סמס",
        },
        input: "למי לשלוח לינק לתשלום",
      },
      submit: "המשך לתשלום",
      spinner: {
        terminal: "נא להמשיך בהתאם להנחיות במסוף האשראי.",
      }
    }
  },
  table: {
    imported: "מסמך יבוא",
    columns: {
      id: "(m)#", // model
      incomeId: "(i)#", // income
      createdAt: "תאריך",
      patientName: "חשבון",
      financialNumber: "מסמך/טיפול",
      incomeType: "סוג",
      incomeStatus: "סטטוס",
      description: "תיאור",
      paymentTypes: "אמצעי תשלום",
      amount: "סכום",
      debit: "חובה",
      credit: "זכות",
      balance: "יתרה",

      documentDate: "תאריך יצירה",
      doctorName: "רופא",
      payAmount: "סכום",
      paid: "שולם",
      treatmentIds: "הטיפולים",
      treatmentPlanIds: "תוכניות טיפול",
    }
  }
}
