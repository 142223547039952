export default {
  head: {
    title: "Incomes",
    meta: [
      { name: "description", content: "Incomes description" }
    ]
  },
  title: "Incomes",
  imported: "Imported",
  filter: {
    search: "Search",
    tooltip: "Search by: client name, description, id",
    documentType: "Document type",
    incomeStatus: "Document status",
    paymentType: "Payment type",
    amountFrom: "Amount from",
    amountTo: "Amount to",
    date: "Date"
  },
  buttons: {
    approve: "Approve",
    preview: "Preview",
    download: "Download",
    send: "Send",
    addRelated: "Add related",
    copy: "Add copy",
    view: "View",
    delete: "Delete draft",
    edit: "Edit draft"
  },
  dropdown: {
    add: "Add income",
    estimate: "Estimate",
    proformaInvoice: "Proforma invoice",
    invoice: "Invoice",
    invoiceReceipt: "Invoice receipt",
    receipt: "Receipt",
    invoiceRefound: "Invoice refound",
    receiptRefound: "Receipt refound"
  },
  table: {
    columns: {
      financialNumber: "Number",
      documentDate: "Date",
      incomeType: "Type",
      patientName: "Client name",
      description: "Description",
      payAmount: "Amount"
    }
  }
}
