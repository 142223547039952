export default {
  title: "טיפולים שהסתיימו",
  search: {
    placeholder: "חיפוש",
    tooltip: "חפש לפי: תיאור, שם שירות, קוד שירות"
  },
  status: {
    yes: "כן",
    no: "לא",
    partial: "חלקי"
  },
  descriptionEmpty: "מומלץ להקפיד לתאר את הטיפול",
  markAsPaid: "סמן כשולם",
  markAsNotPaid: "סימון כלא שולם",
  table: {
    columns: {
      createdAt: "תאריך",
      planId: "תוכנית טיפול",
      startTime: "תאריך",
      doctorName: "רופא",
      serviceName: "שירות",
      serviceCode: "קוד שירות",
      description: "תיאור",
      teeths: "שיניים",
      areas: "משטח",
      quantity: "כמות",
      price: "מחיר",
      discountValue: "הנחה",
      total: "סיכום",
      paidStatus: "שולם",
      statusTitle: "סטטוס"
    }
  }
}
