export default {
  head: {
    title: "תבנית הודעה",
    meta: [
      { name: "description", content: "תבנית הודעה" }
    ]
  },
  table: {
    columns: {
      id: "ID",
      createdAt: "תאריך ושעה",
      name: "נתיב"
    }
  },
  validation: {
    required: "יש למלא שדה זה כדי להמשיך"
  },
  previewPopup: {
    title: "שליחה לבדיקה",
    email: "דוא\"ל",
    phone: "טלפון",
    submit: "שליחה",
    message: "ההודעה נשלחה בהצלחה"
  },
  previewMessage: "שליחה לבדיקה",
  createTitle: "יצירת תבנית הודעה חדשה",
  updateTitle: "עדכן תבנית הודעה {0}",
  systemName: "שם תבנית הודעה",
  subject: "נושא",
  attachments: "קבצים מצורפים",
  platform: "פלטפורמה",
  type: "סוג תבנית",
  text: "טקסט",
  active: "פעיל",
  nameUsed: "שם תבנית ההודעה כבר בשימוש",
  saveBeforeUpload: "יש לשמור את תבנית ההודעה לפני העלאת כל קובץ מצורף"
}
