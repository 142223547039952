export default {
  head: {
    title: "Pricelist groups",
    meta: [
      { name: "description", content: "Pricelist groups" }
    ]
  },
  table: {
    columns: {
      id: "ID",
      title: "Title",
      active: "Active",
      isDefault: "Default",
      displayOrder: "Display order"
    },
  },
  actions: {
    edit: "Edit",
    add: "Add pricelist group"
  }
}
