export default {
  head: {
    title: "MedForm - שנה סיסמא",
    meta: [
      { name: "description", content: "MedForm שנה סיסמא" }
    ]
  },
  title: "שנה סיסמא",
  desc: "הכנס סיסמא חדשה",
  save: "שמור",
  success: "הסיסמא שונתה בהצלחה!",
  input: {
    email: "דוא\"ל",
    passwordNew: "סיסמה חדשה",
    passwordRepeat: "חזור על הסיסמה"
  },
  errors: {
    default: "התרחשה שגיאה, בבקשה נסה שוב",
    invalidToken: "קישור לאיפוס הסיסמה פג תוקף או שכבר השתמשו בו. אנא ליצור קישור חדש לאיפוס הסיסמה."
  }
}
