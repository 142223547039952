export default {
  number: "number",
  print: "Print document",
  downloadOrigin: "Download origin document",
  downloadCopy: "Download copy document",
  whatsAppSharing: "Sharing on WhatsApp",
  emailSharing: "Sharing on email",
  documentProduced: "We have generated your document and it is now available for viewing and sharing",
  refreshBrowser: "Couldn't find it in the documents or client folder? It is recommended to refresh the page and try again.",
  viewDocument: "View document",
  close: "Close"
}