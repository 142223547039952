import { defineStore } from 'pinia'

// https://blog.logrocket.com/complex-vue-3-state-management-pinia/
export const useLayoutStore = defineStore({
  id: 'layout',
  state: () => ({
    layoutType: 'vertical',
    layoutWidth: 'fluid',
    leftSidebarType: 'dark',
    topbar: 'dark',
    loader: false,
    errorCode: 0,
    errorMessage: '',
    errorDescription: '',
    backButtonOnPreviousPage: false,
  }),
  actions: {
    changeLayoutType(layoutType: string) {
      this.layoutType = layoutType;
    },
    changeLayoutWidth(layoutWidth: string) {
      this.layoutWidth = layoutWidth;
    },
    changeLeftSidebarType(leftSidebarType: string) {
      this.leftSidebarType = leftSidebarType;
    },
    changeTopbar(topbar: string) {
      this.topbar = topbar;
    },
    changeLoaderValue(loader: boolean) {
      this.loader = loader
    },
    changeErrorCode(errorCode?: number | null, errorMessage?: string | null, errorDescription?: string | null) {
      this.errorCode = errorCode ?? 0;
      this.errorMessage = errorMessage ?? '';
      this.errorDescription = errorDescription ?? '';
    },
    setBackButtonOnPreviousPage(value: boolean) {
      this.backButtonOnPreviousPage = value;
    }
  }
})
